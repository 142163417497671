import { Link } from 'gatsby';
import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import '../styles/style.scss';

const RegisterPage = () => {
    const [state, setState] = useState(false);
    return (
        <>
            <Helmet title="Ranking Fotowoltaiki - zapisy do serwisu">
                <meta
                    name="description"
                    content="Ranking Fotowoltaiki to serwis zbierający dane o wykonawcach instalacji fotowoltaicznych. Jeżeli Twoja firma instaluje panele słoneczne, dodaj ją do naszego serwisu."
                />
            </Helmet>
            <section className="hero is-fullheight-with-navbar">
                <div className="hero-body">
                    <div className="container">
                        <div className="columns level">
                            <div className="column left">
                                <h1 className="title is-1">Zapisz się!</h1>
                                <h2 className="subtitle colored is-4 h-acc">
                                    ...aby dodać publiczny profil swojej firmy przed startem
                                    serwisu
                                </h2>
                                <p className="block">
                                    Jeżeli jesteś przedstawicielem{' '}
                                    <strong>
                                        firmy, która zajmuje się instalacjami fotowoltaicznymi
                                    </strong>
                                    , zapisz się do naszej bazy przy użyciu formularza po
                                    prawej.
                                </p>
                                <p className="block">
                                    Na kilka tygodni przed publicznym startem naszego serwisu,
                                    umożliwimy Tobie{' '}
                                    <strong>utworzenie publicznego profilu firmy</strong>,
                                    który będzie pojawiał się w naszych rankingach.
                                </p>
                                <p className="block">
                                    Dla firm dokonujacych rejestracji przed publicznym startem
                                    serwisu przewidujemy{' '}
                                    <strong>preferencyjne warunki współpracy</strong>.
                                </p>
                                <Link to="/">
                                    <span
                                        style={{
                                            verticalAlign: '-5%',
                                            fontSize: '30px',
                                            paddingRight: '10px',
                                        }}
                                    >
                                        ‹
                                    </span>{' '}
                                    Wróć do strony głównej.
                                </Link>
                            </div>
                            <div className="column right has-text-centered">
                                <img
                                    src="/ranking-fotowoltaiki-logo-web.svg"
                                    alt="Ranking Fotowoltaiki - logo"
                                    style={{ width: '200px' }}
                                />
                                <h1 className="mt-5 title is-4 h-acc">
                                    Pozostaw dane kontaktowe
                                </h1>
                                <p className="description mb-3">
                                    Odezwiemy się do Ciebie przed publicznym startem serwisu!
                                </p>
                                <form
                                    name="Zapisy"
                                    method="POST"
                                    data-netlify="true"
                                    action="/zapisano"
                                >
                                    <input type="hidden" name="form-name" value="Zapisy" />
                                    <div className="field">
                                        <label className="label has-text-left ml-2">
                                            Imię
                                        </label>
                                        <div className="control">
                                            <input
                                                className="input is-medium"
                                                type="text"
                                                placeholder="Imię"
                                                required
                                                name="Imie"
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label has-text-left ml-2">
                                            Adres e-mail
                                        </label>
                                        <div className="control">
                                            <input
                                                className="input is-medium"
                                                type="email"
                                                placeholder="Firmowy adres e-mail"
                                                required
                                                name="email"
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <label className="label has-text-left ml-2">
                                            Nazwa firmy
                                        </label>
                                        <div className="control">
                                            <input
                                                className="input is-medium"
                                                type="text"
                                                placeholder="Nazwa Twojej firmy"
                                                required
                                                name="firma"
                                            />
                                        </div>
                                    </div>
                                    <div className="field">
                                        <div className="control pl-3">
                                            <label
                                                className="checkbox"
                                                style={{
                                                    display: 'flex',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                <input
                                                    type="checkbox"
                                                    className="mr-3"
                                                    required
                                                ></input>
                                                <div>
                                                    Zapoznałem się z{' '}
                                                    <a
                                                        onClick={(e) => {
                                                            e.preventDefault();
                                                            setState(true);
                                                        }}
                                                    >
                                                        informacją o przetwarzaniu danych
                                                        osobowych
                                                    </a>
                                                    .
                                                </div>
                                            </label>
                                        </div>
                                    </div>
                                    <button className="button is-block is-primary is-fullwidth is-medium">
                                        Wyślij
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                    <div className={`modal ${state ? 'is-active' : ''}`}>
                        <div className="modal-background"></div>
                        <div className="modal-content">
                            <div className="box">
                                <p className="block is-size-6">
                                    Wyrażam zgodę na przetwarzanie moich danych osobowych
                                    zgodnie z ustawą o ochronie danych osobowych w celu
                                    nawiązania współpracy biznesowej z moją firmą. Jednocześnie
                                    oświadczam, że zostałam poinformowany o przysługującym mi
                                    prawie dostępu do treści moich danych oraz ich poprawiania,
                                    wycofania zgody na ich przetwarzanie w każdym czasie, jak
                                    również, że podanie tych danych było dobrowolne.
                                    Administratorem danych jest firma IQcode Technologie
                                    Internetowe Paweł Kuffel z siedzibą w Warszawie przy ulicy
                                    Jana Karola Chodkiewicza 7/22, kod pocztowy: 02-593.
                                </p>
                                <button
                                    className="button is-primary"
                                    onClick={() => {
                                        setState(false);
                                    }}
                                >
                                    Zamknij
                                </button>
                            </div>
                        </div>
                        <button
                            className="modal-close is-large"
                            aria-label="close"
                            onClick={() => {
                                setState(false);
                            }}
                        ></button>
                    </div>
                </div>
            </section>
        </>
    );
};

export default RegisterPage;
